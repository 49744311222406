export default {
  'zh-cn': {
    i18n: {
      breadcrumb: '国际化产品',
      tips: '通过切换语言按钮，来改变当前内容的语言。',
      btn: '切换英文',
      title1: '常用用法',
      p1: '要是你把你的秘密告诉了风，那就别怪风把它带给树。',
      p2: '没有什么比信念更能支撑我们度过艰难的时光了。',
      p3: '只要能把自己的事做好，并让自己快乐，你就领先于大多数人了。'
    }
  },
  'en': {
    i18n: {
      breadcrumb: 'International Products',
      tips: 'Click on the button to change the current language. ',
      btn: 'Switch Chinese',
      title1: 'Common usage',
      p1: "If you reveal your secrets to the wind you should not blame the wind for  revealing them to the trees.",
      p2: "Nothing can help us endure dark times better than our faith. ",
      p3: "If you can do what you do best and be happy, you're further along in life  than most people."
    }
  }
}