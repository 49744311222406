<template>
  <div class="sidebar">
    <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#292D31"
      text-color="#bfcbd9" active-text-color="#20a0ff" unique-opened router>
      <div v-if="showtitle" class="titlediv" @click="collapseChage">
        <div class="logo">空气骑士管理平台</div>
      </div>
      <div class="titlediv" v-else @click="collapseChage">
        <img src="../assets/img/logo.png" alt="" />
      </div>
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                <template #title>{{ subItem.title }}</template>
                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">
                  {{ threeItem.title }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="subItem.index" :key="subItem.index">{{ subItem.title }}</el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <i :class="item.icon"></i>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
      <div class="shousuo" @click="collapseChage">
        <p v-if="showtitle"><i class="el-icon-lx-back"></i><span>收起</span></p>
        <p v-else><i class="el-icon-lx-right"></i><span>展开</span></p>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showtitle: true,
      items: [],
    };
  },
  watch: {
    "$store.state.collapse" (newval) {
      // console.log(newval, "newval");
      this.showtitle = !newval;
    },
  },

  methods: {
    // 侧边栏折叠
    collapseChage () {
      this.$store.commit("hadndleCollapse", !this.collapse);
    },
  },
  mounted () {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
    // 改变侧边栏
    let roleKey = localStorage.getItem("show_token");
    if (roleKey == "superAdmin") {
      this.items = [
        // 数据总览
        {
          icon: "el-icon-lx-rankfill",
          index: "indexpage",
          title: "数据总览",
        },
        // 用户管理
        {
          icon: "el-icon-lx-group",
          index: "1",
          title: "用户管理",
          subs: [
            {
              index: "user_list",
              title: "用户列表",
            },
            {
              index: "avatarnicknameapproval",
              title: "头像昵称审批",
            },
            {
              index: "feedback",
              title: "意见反馈",
            },
          ],
        },
        // 消毒机设备管理
        {
          icon: "el-icon-cpu",
          index: "2",
          title: "消毒机设备管理",
          subs: [
            // {
            //   index: "equipment_list",
            //   title: "低温等离子体消毒机(家居)",
            // },
            {
              index: "dynamicwaterion_list",
              title: "动态水离子消毒机(车载)",
            },
          ],
        },
        // 设备流量管理
        // {
        //   icon: "el-icon-s-marketing",
        //   index: "trafficmanagement",
        //   title: "设备流量管理",
        // },
        // 检测报告管理
        {
          icon: "el-icon-lx-read",
          index: "testreport",
          title: "检测报告管理",
        },
        // 新闻管理
        {
          icon: "el-icon-lx-news",
          index: "news",
          title: "新闻管理",
        },

        // 打卡积分管理
        {
          icon: "el-icon-lx-favor",
          index: "3",
          title: "打卡积分管理",
          subs: [
            {
              index: "userpoint_list",
              title: "用户积分列表",
            },
            // {
            //   index: "exchange_list",
            //   title: "兑换列表",
            // },
          ],
        },

        // APP管理
        {
          icon: "el-icon-coin",
          index: "4",
          title: "App管理",
          subs: [
            // {
            //   index: "app_basesetting",
            //   title: "基本设置",
            // },
            // 安卓
            {
              index: "app_android",
              title: "安卓版本管理",
            },
            // IOS
            {
              index: "app_ios",
              title: "IOS版本管理",
            },
            // {
            //   index: "app_edition",
            //   title: "版本管理",
            // },
            // {
            //   index: "app_regagreement",
            //   title: "注册协议",
            // },
            // {
            //   index: "app_manual",
            //   title: "操作手册",
            // },
            // {
            //   index: "app_abuotus",
            //   title: "关于我们",
            // },
          ],
        },

        // 系统管理
        {
          icon: "el-icon-monitor",
          index: "5",
          title: "系统管理",
          subs: [
            {
              index: "systemlog",
              title: "系统日志",
            },
            {
              index: "systemuser",
              title: "系统用户",
            },
          ],
        },

        // 其他消毒机相关
        {
          icon: "el-icon-ship",
          index: "6",
          title: "一些相关内容",
          subs: [
            // 商务合作
            {
              index: "cooperation",
              title: "商务合作",
            },
            // 消毒机扫码的token添加 暂时未做
            // {
            //   index: "addtoken",
            //   title: "更新工厂扫码token",
            // },
          ],
        },
      ];
    } else if (roleKey == "admin") {
      this.items = [
        // 数据总览
        {
          icon: "el-icon-lx-rankfill",
          index: "indexpage",
          title: "数据总览",
        },
        // 用户管理
        {
          icon: "el-icon-lx-group",
          index: "1",
          title: "用户管理",
          subs: [
            {
              index: "user_list",
              title: "用户列表",
            },
            {
              index: "avatarnicknameapproval",
              title: "头像昵称审批",
            },
            {
              index: "feedback",
              title: "意见反馈",
            },
          ],
        },
        // 消毒机设备管理
        {
          icon: "el-icon-cpu",
          index: "2",
          title: "消毒机设备管理",
          subs: [
            // {
            //   index: "equipment_list",
            //   title: "低温等离子体消毒机(家居)",
            // },
            {
              index: "dynamicwaterion_list",
              title: "动态水离子消毒机(车载)",
            },
          ],
        },
        // 设备流量管理
        // {
        //   icon: "el-icon-s-marketing",
        //   index: "trafficmanagement",
        //   title: "设备流量管理",
        // },
        // 检测报告管理
        {
          icon: "el-icon-lx-read",
          index: "testreport",
          title: "检测报告管理",
        },
        // 新闻管理
        {
          icon: "el-icon-lx-news",
          index: "news",
          title: "新闻管理",
        },

        // 打卡积分管理
        {
          icon: "el-icon-lx-favor",
          index: "3",
          title: "打卡积分管理",
          subs: [
            {
              index: "userpoint_list",
              title: "用户积分列表",
            },
            // {
            //   index: "exchange_list",
            //   title: "兑换列表",
            // },
          ],
        },

        // APP管理
        {
          icon: "el-icon-coin",
          index: "4",
          title: "App管理",
          subs: [
            // {
            //   index: "app_basesetting",
            //   title: "基本设置",
            // },
            // 安卓
            {
              index: "app_android",
              title: "安卓版本管理",
            },
            // IOS
            {
              index: "app_ios",
              title: "IOS版本管理",
            },
            // {
            //   index: "app_edition",
            //   title: "版本管理",
            // },
            // {
            //   index: "app_regagreement",
            //   title: "注册协议",
            // },
            // {
            //   index: "app_manual",
            //   title: "操作手册",
            // },
            // {
            //   index: "app_abuotus",
            //   title: "关于我们",
            // },
          ],
        },
      ];
    } else if (roleKey == "operator") {
      this.items = [
        // 数据总览
        {
          icon: "el-icon-lx-rankfill",
          index: "indexpage",
          title: "数据总览",
        },
        // 用户管理
        {
          icon: "el-icon-lx-group",
          index: "1",
          title: "用户管理",
          subs: [
            {
              index: "user_list",
              title: "用户列表",
            },
            {
              index: "avatarnicknameapproval",
              title: "头像昵称审批",
            },
            {
              index: "feedback",
              title: "意见反馈",
            },
          ],
        },
        // 检测报告管理
        {
          icon: "el-icon-lx-read",
          index: "testreport",
          title: "检测报告管理",
        },
        // 新闻管理
        {
          icon: "el-icon-lx-news",
          index: "news",
          title: "新闻管理",
        },
      ];
    }
  },
  computed: {
    onRoutes () {
      return this.$route.path.replace("/", "");
    },
    collapse () {
      return this.$store.state.collapse;
    },
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}
.el-menu {
  border-right: none;
}

.titlediv {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  width: 100%;
  text-align: center;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.shousuo {
  width: 84%;
  height: 50px;
  background-color: #fff;
  position: absolute;
  bottom: 40px;
  border-radius: 0 12px 12px 0;
  background-color: rgba(96, 99, 102, 1);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.shousuo p {
  margin-right: 8px;
  color: #fff;
  white-space: nowrap;
}
</style>
