export default {
  data () {
    return {
      //定时器
      timer: null,
    }
  },
  beforeUpdate () {
    // //清除定时器
    // clearInterval(this.timer)
    // this.timer = null
  },
  beforeUnmount () {
    //清除定时器
    clearInterval(this.timer)
    this.timer = null
  },
}