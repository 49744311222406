import { createApp } from 'vue'
// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './assets/css/icon.css'
import '@/assets/font/font.css'
import 'amfe-flexible'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

// import { VueJsonp } from 'vue-jsonp'
// import ElementPlus from 'element-plus'
// import zhCn from 'element-plus/es/locale/lang/zh-cn'

// app.use(ElementPlus, {
//   locale: zhCn,
// })

// import publicjs from './assets/commen/index.js'
// Vue.prototype.$public = publicjs


const app = createApp(App)
installElementPlus(app)
app
	.use(store)
	.use(router)
	// .use(ElementUI, { zhLocale })
	// .use(VueJsonp)
	.mount('#app')
