import request from '../utils/request';

// 获取设备总览
export const getdeviceOverview = () => {
  return request({
    url: '/kanban/api/deviceOverview',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

// 获取操作记录
export const operateRecord = query => {
  return request({
    url: '/kanban/api/operateRecord',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

// 折线图
export const getstartTimes = () => {
  return request({
    url: '/kanban/api/startTimes',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

//获取当前版本号
export const getLatestVersionNo = () => {
  return request({
    url: '/kanban/system/getLatestVersionNo',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//更新天青设备状态.
export const updateTQStatus = () => {
  return request({
    url: '/kanban/TQ/updateTQStatus',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

//更新天青设备状态.
export const getToiletData = () => {
  return request({
    url: '/kanban/TQ/getGcData',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export const getClientCfg = () => {
  return request({
    url: "/kanban/api/getClientInfo",
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}