<template>
  <router-view v-if="isRouterAlive" />
</template>

<script>
import clearTime from "@/mixin/clearTime";
import { qingqiu, getFuRui, getFuRuiShi } from "@/api/Robot.js";
import { getLatestVersionNo } from "@/api/kanban";
import { getClientCfg } from "./api/kanban";
export default {
  mixins: [clearTime],
  async created() {
    const res = await getClientCfg();
    console.log(res, "getClientCfg");
    if (res.data) {
      this.$store.commit("changetitle", res.data.clientName);
      sessionStorage.setItem("a_title", res.data.clientName);
    }
    //获取当前版本号
    this.getLatestVersionNo();
    this.timer = setInterval(() => {
      this.getLatestVersionNo();
    }, 5000);
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      //版本号
      VersionNo: localStorage.getItem("VersionNo") || 0,
    };
  },

  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },

    async getFuRui() {
      try {
        await getFuRui();
      } catch (error) {
        console.log(error);
      }
    },

    //获取当前版本号
    async getLatestVersionNo() {
      const res = await getLatestVersionNo();
      if (res.data > this.VersionNo) {
        this.VersionNo = res.data;
        localStorage.setItem("VersionNo", this.VersionNo);
        //有新版本就刷新页面
        location.reload();
      } else {
        this.VersionNo = res.data;
        localStorage.setItem("VersionNo", this.VersionNo);
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";
@import "./assets/css/common.scss";
@import "./assets/font/font.css";
//标题
.header_title {
  display: flex;
  align-items: center;
  .yuan {
    margin-right: 5px;
    width: 12px;
    height: 12px;
    border: 2px solid #08f4da;
    border-radius: 12px;
  }
  .header_ch {
    margin-right: 8px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  .header_en {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #08f4da;
  }
}

//表格表头的样式
.tabListHeader {
  position: relative;
  width: 100%;
  height: 36px;
  line-height: 36px;
  background: linear-gradient(
    125deg,
    rgba(19, 250, 239, 0.3) 0%,
    rgba(8, 244, 218, 0) 100%
  );
  border-radius: 5px;
  font-size: 16px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #ffffff;
  .tabListHeader_after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 22px;
    background: #f7dd70;
    border-radius: 3px;
  }
}
.tabListHeader::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  display: block;
  width: 8px;
  height: 22px;
  background: #f7dd70;
  border-radius: 3px;
}
</style>
