import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home/Home.vue";
import { ElMessage } from "element-plus";
import store from '../store/index'

const routes = [
	// 重定向
	{
		path: '/',
		redirect: '/login'
	},
	// Home下
	{
		path: "/",
		name: "Home",
		component: Home,
		children: [
			// 数据总览
			{
				path: "/contorlpage",
				name: "contorlpage",
				meta: {
					title: '控制台'
				},
				component: () => import(
					"../views/Concenter/contorlpage.vue")
			},
			{
				path: "/exhibition",
				name: "exhibition",
				meta: {
					title: '显示'
				},
				component: () => import(
					"../views/Exhibition/exhibition.vue")
			},
			{
				path: "/list",
				name: "list",
				meta: {
					title: '列表'
				},
				component: () => import(
					"../views/List/list.vue")
			},
			{
				path: "/list2",
				name: "list2",
				meta: {
					title: '列表'
				},
				component: () => import(
					"../views/list2/index.vue")
			},
			{
				path: "/AllPanle",
				name: "AllPanle",
				meta: {
					title: '显示'
				},
				component: () => import(
					"../views/AllPanle/index.vue")
			},
			{
				path: "/robot",
				name: "robot",
				meta: {
					title: '显示'
				},
				component: () => import(
					"../views/robot/index.vue")
			},
			{
				path: "/oneRobot",
				name: "oneRobot",
				meta: {
					title: '显示'
				},
				component: () => import(
					"../views/oneRobot/index.vue")
			},
			{
				path: "/twoRobot",
				name: "twoRobot",
				meta: {
					title: '显示'
				},
				component: () => import(
					"../views/twoRobot/index.vue")
			},

			{ //消毒机看板 版本4
				path: "/sterilizer",
				name: "sterilizer",
				meta: {
					title: '显示'
				},
				component: () => import(
					"../views/Sterilizer")
			},

			{ //消毒机看板 版本4
				path: "/sterilizerRobot",
				name: "sterilizerRobot",
				meta: {
					title: '显示'
				},
				component: () => import(
					"../views/SterilizerRobot")
			},
			{ //消毒机看板 版本4
				path: "/whole",
				name: "whole",
				meta: {
					title: '显示'
				},
				component: () => import(
					"../views/Whole")
			},
			{ //厕所消毒机
				path: "/toiletAntivirusDevice",
				name: "toiletAntivirusDevice",
				meta: {
					title: '后堡公园公厕智能消毒及卫生监测系统'
				},
				component: () => import(
					"../views/ToiletAntivirusDevice")
			},

			// 用户管理下--》用户列表
			// {
			//   path: '/user_list',
			//   name: 'user_list',
			//   meta: {
			//     title: '用户列表'
			//   },
			//   component: () => import(
			//     '../views/Usermanagement/Userlist.vue')
			// },
			// // 用户管理下--》头像昵称审批
			// {
			//   path: '/avatarnicknameapproval',
			//   name: 'avatarnicknameapproval',
			//   meta: {
			//     title: '头像昵称审批'
			//   },

			//   component: () => import(
			//     '../views/Usermanagement/Avatarnicknameapproval.vue')
			// },
			// // 用户管理下--》意见反馈
			// {
			//   path: '/feedback',
			//   name: 'feedback',
			//   meta: {
			//     title: '意见反馈'
			//   },
			//   component: () => import(
			//     '../views/Usermanagement/Feedback.vue')
			// },

			// // 消毒机设备管理下 --》 低温等离子体消毒机
			// {
			//   path: '/equipment_list',
			//   name: 'equipment_list',
			//   meta: {
			//     title: '低温等离子体消毒机'
			//   },
			//   component: () => import(
			//     '../views/Devicemanagement/Equipment_list.vue')
			// },
			// // 消毒机设备管理下 --》 动态水离子消毒机
			// {
			//   path: '/dynamicwaterion_list',
			//   name: 'dynamicwaterion_list',
			//   meta: {
			//     title: '动态水离子消毒机'
			//   },
			//   component: () => import(
			//     '../views/Devicemanagement/Dynamicwaterion_list.vue')
			// },

			// // 设备流量管理
			// {
			//   path: '/trafficmanagement',
			//   name: 'trafficmanagement',
			//   meta: {
			//     title: '设备流量管理'
			//   },
			//   component: () => import(
			//     '../views/Trafficmanagement/Trafficmanagement.vue')
			// },

			// // 检测报告管理
			// {
			//   path: '/testreport',
			//   name: 'testreport',
			//   meta: {
			//     title: '检测报告管理'
			//   },
			//   component: () => import(
			//     '../views/Testreport/Testreport.vue')
			// },

			// // 新闻管理
			// {
			//   path: '/news',
			//   name: 'news',
			//   meta: {
			//     title: '新闻管理'
			//   },
			//   component: () => import(
			//     '../views/News/News.vue')
			// },
			// // 打卡积分管理 --》 用户积分列表
			// {
			//   path: '/userpoint_list',
			//   name: 'userpoint_list',
			//   meta: {
			//     title: '用户积分列表'
			//   },
			//   component: () => import(
			//     '../views/Punchandpoints/Userpoint_list.vue')
			// },
			// // 打卡积分管理 --》 兑换列表
			// {
			//   path: '/exchange_list',
			//   name: 'exchange_list',
			//   meta: {
			//     title: '兑换列表'
			//   },
			//   component: () => import(
			//     '../views/Punchandpoints/Exchange_list.vue')
			// },

			// // APP管理 -->基本设置
			// {
			//   path: '/app_basesetting',
			//   name: 'app_basesetting',
			//   meta: {
			//     title: '基本设置'
			//   },
			//   component: () => import(
			//     '../views/APP/App_Basesetting.vue')
			// },
			// // APP管理 -->安卓版本管理
			// {
			//   path: '/app_android',
			//   name: 'app_android',
			//   meta: {
			//     title: '安卓版本管理'
			//   },
			//   component: () => import(
			//     '../views/APP/App_Android.vue')
			// },
			// // APP管理 -->IOS版本管理
			// {
			//   path: '/app_ios',
			//   name: 'app_ios',
			//   meta: {
			//     title: 'IOS版本管理'
			//   },
			//   component: () => import(
			//     '../views/APP/App_Ios.vue')
			// },
			// // APP管理 -->版本管理
			// {
			//   path: '/app_edition',
			//   name: 'app_edition',
			//   meta: {
			//     title: '版本管理'
			//   },
			//   component: () => import(
			//     '../views/APP/App_Edition.vue')
			// },
			// // APP管理 -->注册协议
			// {
			//   path: '/app_regagreement',
			//   name: 'app_regagreement',
			//   meta: {
			//     title: '注册协议'
			//   },
			//   component: () => import(
			//     '../views/APP/App_RegAgreement.vue')
			// },
			// // APP管理 -->操作手册
			// {
			//   path: '/app_manual',
			//   name: 'app_manual',
			//   meta: {
			//     title: '操作手册'
			//   },
			//   component: () => import(
			//     '../views/APP/App_Manual.vue')
			// },
			// // APP管理 -->关于我们
			// {
			//   path: '/app_abuotus',
			//   name: 'app_abuotus',
			//   meta: {
			//     title: '关于我们'
			//   },
			//   component: () => import(
			//     '../views/APP/App_Abuotus.vue')
			// },
			// // 系统管理 --》系统日志
			// {
			//   path: '/systemlog',
			//   name: 'systemlog',
			//   meta: {
			//     title: '系统日志'
			//   },
			//   component: () => import(
			//     '../views/Systemmanagement/Systemlog.vue')
			// },
			// // 系统管理 --》系统用户
			// {
			//   path: '/systemuser',
			//   name: 'systemuser',
			//   meta: {
			//     title: '系统用户'
			//   },
			//   component: () => import(
			//     '../views/Systemmanagement/Systemuser.vue')
			// },
			// // 商务合作
			// {
			//   path: '/cooperation',
			//   name: 'cooperation',
			//   meta: {
			//     title: '商务合作'
			//   },
			//   component: () => import(
			//     '../views/Cooperation/Cooperation.vue')
			// },
			// // 添加token
			// {
			//   path: '/addtoken',
			//   name: 'addtoken',
			//   meta: {
			//     title: '更新工厂扫码token'
			//   },
			//   component: () => import(
			//     '../views/Cooperation/Addtoken.vue')
			// },
		]
	},
	// 登录
	{
		path: "/login",
		name: "login",
		meta: {
			title: '登录'
		},
		component: () => import(
			"@/views/Login/Login.vue")
	},
	{
		path: "/DevicesNowTime",
		name: "DevicesNowTime",
		meta: {
			title: '设备实时看版'
		},
		component: () => import(
			"@/views/DevicesNowTime")
	}
];

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
});

// router.beforeEach((to, from, next) => {
//   document.title = `${to.meta.title} | 空气骑士`;
//   const role = sessionStorage.getItem('show_token');
//   if (!role && to.path !== '/login') {
//     next('/login');
//   } else if (to.meta.permission) {
//     // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
//     role === 'admin'
//       ? next()
//       : next('/403');
//   } else {
//     next();
//   }
// });


// 路由守卫，前置路由守卫
router.beforeEach((to, from, next) => {
	// 判断是否是去看板还是列表，如果是就显示返回，如果不是就隐藏
	// console.log(to, 'to', from, 'from', next, 'next')
	if (to.path == '/exhibition' || to.path == '/list') {
		// 去看板
		store.commit('showback', true)
	} else {
		store.commit('showback', false)
	}
	document.title = `${to.meta.title} | 重庆铭智生科`;
	// console.log(window.sessionStorage.getItem('token'),'路由前置守卫的token');
	let token = localStorage.getItem('show_token') || ''
	// let token = ''
	// console.log(to,'to');
	// to:要去的路由
	// from: 从哪里出发的路由对象
	if (to.path !== "/login") { // 去到非登录页面
		// 判断有没有登录 判断token 是否存在
		if (token) {
			next() // 有token的话 直接放心跳转到你想去的页面
		} else {
			ElMessage.error("请先登录"); // 提示语句
			setTimeout(() => {
				next('/login') // 否则的话直接跳转到 我的  页面进行登录和注册
			}, 0)
		}
		return // 这个地方不写return 代码会往下跑 先去 你点击的购物车页面 再才跳转到user页面去 写了return以后就不会出现这种情况了
	}
	next(); // 可以通往要去的路由,如果不写的话 就访问不到对应的路由
});


export default router;
