import request from '../utils/request';
//获取达闼机器人数据
export const qingqiu = () => {
	return request({
		url: '/kanban/api/gainData',
		method: 'GET',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
};
//更新数据     （调一次）
export const getFuRui = () => {
	return request({
		url: '/kanban/robot/getFuRui',
		method: 'GET',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
};
//发送指令
export const getFuRuiShi = () => {
	return request({
		url: '/kanban/robot/getFuRuiShi',
		method: 'GET',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
};

//每台设备累计消毒时长
export const getHour = () => {
	return request({
		url: '/kanban/robot/getHour',
		method: 'GET',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
};
//网络状态，设备位置检测
export const getNetLocation = () => {
	return request({
		url: '/kanban/robot/getNetLocation',
		method: 'GET',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
};
//近一周运行时长
export const getRunningHours = () => {
	return request({
		url: '/kanban/robot/getRunningHours',
		method: 'GET',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
};

//近一周设备使用记录
export const getWeekUser = () => {
	return request({
		url: '/kanban/robot/getWeekUser',
		method: 'GET',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
};
//获取累计消毒时长
export const getWorkingHours = () => {
	return request({
		url: '/kanban/robot/getWorkingHours',
		method: 'GET',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
};
