import axios from 'axios';
import qs from 'qs'
// import baseURL from '../api/baseUrl'
import $config from '@/api/baseUrl';
import router from '@/router'
import Message from 'element-plus/lib/el-message';
const service = axios.create({
  // baseURL:"http://www.e-rijiben.com:8001",//正式地址，打包前打开并将反向代理去掉
  baseURL: $config.baseUrl,
  timeout: 1180000,
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    if (localStorage.getItem('show_token')) {
      config.headers.token = localStorage.getItem('show_token')
    }
    // console.log(config,'config')
    // 判断自定义请求类型：CustomRequestType，true：json类型 
    if (config.headers.CustomRequestType) {
      config.data
    } else {
      config.data = qs.stringify(config.data)// 转为formdata数据格式
    }

    // return
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject();
  }
);
// 响应拦截器  
service.interceptors.response.use(
  response => {
    // console.log(response,'response')
    if (response.status === 200) {
      if (response.data && response.data.code == 200) {
        return response.data;
      }
      else if (response.data && response.data.code == 401) {
        router.push('/login')
        return Promise.reject(new Error(response.data.msg));
      }
      else {
        Message.error(response.data.msg)
        return Promise.reject(new Error(response.data.msg));
      }
    } else {
      return Promise.reject();
    }
  },
  error => {
    return Promise.reject(error);
  }
);

export default service;
