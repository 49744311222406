<template>
  <div class="about">
    <!-- <v-header />
    <v-sidebar /> -->

    <div class="content-box" :class="{ 'content-collapse': collapse }">
      <!-- <v-tags></v-tags> -->
      <div class="content">
        <router-view v-slot="{ Component }">
          <transition name="move" mode="out-in">
            <keep-alive :include="tagsList">
              <component :is="Component" />
            </keep-alive>
          </transition>
        </router-view>
        <el-backtop target=".content"></el-backtop>
      </div>
    </div>
  </div>
</template>
<script>
import vHeader from "../../components/Header";
import vSidebar from "../../components/Sidebar";
import vTags from "../../components/Tags.vue";
import updateStatus from '@/mixin/updateStatus.js'  //向后端获取天青数据
import { qingqiu, getFuRui, getFuRuiShi } from "@/api/Robot.js"
export default {
  mixins: [updateStatus],
  data () {
    return {
      timer2: null
    }
  },
  created () {
    //向后端发送数据
    this.qingqiu()
    this.timer2 = setInterval(() => {
      this.qingqiu()
    }, 900000)
  },
  beforeUnmount () {
    //清除定时器
    clearInterval(this.timer2)
    this.timer2 = null
  },
  methods: {
    async qingqiu () {
      try {
        await qingqiu()
      } catch (error) {
        console.log(error);
      }

    },
  },
  components: {
    vHeader,
    vSidebar,
    vTags,
  },
  computed: {
    tagsList () {
      return this.$store.state.tagsList.map((item) => item.name);
    },
    collapse () {
      return this.$store.state.collapse;
    },
  },

};
</script>
