import { updateTQStatus } from '@/api/kanban'
export default {
  data () {
    return {
      //定时器
      timerUp: null,
    }
  },
  created () {
    let clientId = sessionStorage.getItem('clientId')
    if (clientId == '95') {
      this.updateTQStatus()
      this.timerUp = setInterval(() => {
        this.updateTQStatus()
      }, 179000)
    }
  },
  beforeUnmount () {
    //清除定时器
    clearInterval(this.timerUp)
    this.timerUp = null
  },
  methods: {
    async updateTQStatus () {
      const res = await updateTQStatus()
      // console.log('update', res);
    }
  }
}